import type { PropsWithChildren } from 'react';
import type React from 'react';

const SideBarBody: React.FC<PropsWithChildren> = (
    { children },
) => (

    <div className="drawer-content">
        {children}
    </div>
);

export default SideBarBody;
