import { useContext } from 'react';
import { TranslationContext } from '../TranslationProvider';
import ErrorLogger from '../../../../js/error-logger/ErrorLogger';

interface Options {
    placeholders?: Record<string, string | number>;
    strict?: boolean;
}

type UseTranslate = () => (page: string, id: string, options?: Options) => string;

const useTranslate: UseTranslate = () => {
    const translations = useContext(TranslationContext);

    if (translations === undefined) {
        ErrorLogger.createFromGlobals()?.log('useTranslate hook was used outside of the TranslationProvider');
        return () => '';
    }

    return (page, id, options): string => {
        const translation = translations[page]?.[id];

        const strict = options?.strict ?? true;
        if (!translation && strict) {
            ErrorLogger.createFromGlobals()?.log(`Translation not found for page: ${page}, id: ${id}`);
        }

        return Object.entries(options?.placeholders ?? {}).reduce((accumulator, [placeholder, value]) => accumulator.replace(`<${placeholder}>`, String(value)), translation ?? '');
    };
};

export default useTranslate;
