/* eslint-disable import/no-import-module-exports */
import { createRoot, hydrateRoot } from 'react-dom/client';
import { document, window } from '../../../js/globals';
import MySurpriseHeaderWidgetApp from './Components/App/MySurpriseHeaderWidgetApp';

if (module.hot) {
    module.hot.accept();
}

if (document && window) {
    const ssrElement = document.getElementById('mysurprise-header-app-ssr');

    const { MYSURPRISE_HEADER_WIDGET_STATE } = window;

    if (ssrElement) {
        hydrateRoot(ssrElement, <MySurpriseHeaderWidgetApp preloadedState={MYSURPRISE_HEADER_WIDGET_STATE} />);
    } else {
        const fallbackElement = document.getElementById('mysurprise-header-app');

        if (fallbackElement) {
            const root = createRoot(fallbackElement);
            root.render(<MySurpriseHeaderWidgetApp preloadedState={MYSURPRISE_HEADER_WIDGET_STATE} />);
        }
    }
}
