import type { FC } from 'react';
import React from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import AccountButton from '../AccountButton/AccountButton';
import AuthenticationProvider from '../../../../mysurprise/Shared/Provider/AuthenticationProvider/AuthenticationProvider';
import TopLayerProvider from '../../../../general/TopLayer/TopLayerContext';
import WebshopContextProvider from '../../../../general/WebshopContext/WebshopContextProvider';
import StateProvider from '../../Provider/StateProvider';
import type { MySurpriseHeaderWidgetState } from '../../types';
import createQueryClient from '../../../../general/query/QueryClientFactory';

const queryClient = createQueryClient();

interface Props {
    preloadedState: MySurpriseHeaderWidgetState;
}

const MySurpriseHeaderWidgetApp: FC<Props> = ({ preloadedState }) => (
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            <AuthenticationProvider>
                <WebshopContextProvider data={preloadedState.siteMetaData}>
                    <StateProvider state={preloadedState}>
                        <TopLayerProvider>
                            <AccountButton />
                        </TopLayerProvider>
                    </StateProvider>
                </WebshopContextProvider>
            </AuthenticationProvider>
        </QueryClientProvider>
    </React.StrictMode>
);

export default MySurpriseHeaderWidgetApp;
