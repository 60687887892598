import type { ButtonHTMLAttributes, PropsWithChildren } from 'react';
import React from 'react';
import classNames from 'classnames';
import type { IconName } from '../../../stories/Atoms/Icon/Icon';
import Icon from '../../../stories/Atoms/Icon/Icon';

export enum Size {
    lg = 'lg',
    md = 'md',
    sm = 'sm',
    xs = 'xs',
}

type ButtonProps = {
    autoFocus?: boolean;
    className?: string;
    href?: string;
    icon?: IconName;
    iconOnly?: boolean;
    isClean?:boolean;
    isFluid?:boolean;
    isGhost?: boolean;
    isHappyPath?:boolean;
    isLight?: boolean;
    isLoading?: boolean;
    isPrimary?: boolean;
    isSecondary?: boolean;
    isSplit?: boolean;
    isSquare?:boolean;
    isWhite?: boolean;
    size?: Size;
    target?: string;
    textColor?: string;
} & Pick<ButtonHTMLAttributes<HTMLButtonElement>, 'aria-label' | 'onClick' | 'type' | 'disabled'>;

const Button: React.FC<PropsWithChildren<ButtonProps>> = ({
    'aria-label': ariaLabel,
    children,
    className,
    disabled,
    href,
    icon,
    iconOnly,
    isClean,
    isFluid,
    isGhost,
    isHappyPath,
    isLight,
    isLoading,
    isPrimary,
    isSecondary,
    isSplit,
    isSquare,
    isWhite,
    onClick,
    size,
    target,
    textColor,
    type = 'button',
    ...restProps
}) => React.createElement(
    typeof href === 'string' ? 'a' : 'button',
    {
        'aria-label': ariaLabel,
        className: classNames('button', className, {
            'button--clean': isClean,
            'button--fluid': isFluid,
            'button--ghost': isGhost,
            'button--happy-path': isHappyPath,
            'button--has-icon': !!icon,
            'button--light': isLight,
            'button--primary': isPrimary,
            'button--secondary': isSecondary,
            'button--split': isSplit,
            'button--square': isSquare,
            'button--white': isWhite,
            disabled,
            'is-loading': isLoading,
        }, size ? `button--${size}` : null),
        disabled,
        href,
        onClick,
        style: textColor ? { color: textColor } : {},
        target,
        type,
        ...restProps,
    },
    <>
        {!iconOnly && <span>{children}</span>}
        {!!icon && <Icon name={icon}/>}
    </>,
);

export default Button;
