import type { PropsWithChildren } from 'react';
import type React from 'react';
import { useIsMobile } from '../hooks/useSize';
import MobileSideBar from './MobileSideBar';
import DesktopSideBar from './DesktopSideBar';
import { useIsInitialRequestMobile } from '../WebshopContext/WebshopContext';

export interface SideBarProps {
    hasDesktopOverlay?: boolean;
    isMobileDynamicHeight?: boolean;
    noTextAlign?: boolean;
}

const ANIMATION_TIME = 300;

const SideBar: React.FC<PropsWithChildren<SideBarProps>> & { ANIMATION_TIME: number } = ({ children, hasDesktopOverlay = false, isMobileDynamicHeight = false, noTextAlign = false }) => {
    const isMobile = useIsMobile(useIsInitialRequestMobile());

    return isMobile
        ? <MobileSideBar noTextAlign={noTextAlign} isDynamicHeight={isMobileDynamicHeight}>{children}</MobileSideBar>
        : <DesktopSideBar noTextAlign={noTextAlign} hasDesktopOverlay={hasDesktopOverlay}>{children}</DesktopSideBar>;
};

SideBar.ANIMATION_TIME = ANIMATION_TIME;

export default SideBar;
