import type { FC, PropsWithChildren, ReactElement } from 'react';
import { createContext, useCallback, useRef, useState } from 'react';

export type SideBarContextSchema = {
    closeTopLayerElement: () => void;
    createTopLayerElement: (children: ReactElement, animationSpeed?: number) => void;
    isOpen: boolean;
};

export const TopLayerContext = createContext<SideBarContextSchema>({} as SideBarContextSchema);
const TopLayerProvider: FC<PropsWithChildren> = ({ children }) => {
    const [topLayerElement, setTopLayerElement] = useState<ReactElement>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [animationSpeed, setAnimationSpeed] = useState<number | undefined>();
    const timer = useRef<NodeJS.Timeout | number>();

    const createTopLayerElement = useCallback((contents: ReactElement, animation?: number) => {
        if (contents.key === null) {
            throw new Error('The "key" prop is required for JSX elements that are passed to the createTopLayerElement function.');
        }

        clearTimeout(timer.current);

        setAnimationSpeed(animation);
        setIsOpen(true);
        setTopLayerElement(contents);
    }, []);

    const closeTopLayerElement = useCallback(() => {
        setIsOpen(false);

        timer.current = setTimeout(() => {
            setTopLayerElement(undefined);
            /**
             * Allow the configuration of a delay so animations are able to finish. E.g. the sidebar or sheet closing animation.
             */
        }, animationSpeed);

        setAnimationSpeed(undefined);

        return () => clearTimeout(timer.current);
    }, [animationSpeed]);

    return (
        <TopLayerContext.Provider value={{ closeTopLayerElement, createTopLayerElement, isOpen }}>
            <>
                {children}
                {topLayerElement}
            </>
        </TopLayerContext.Provider>
    );
};

export default TopLayerProvider;
