class Url {
    public static getPath(urlString: string): string | null {
        try {
            const url = new URL(urlString);

            return url.pathname + url.search;
        } catch (error) {
            return null;
        }
    }

    public static getPathWithoutSubLanguage(urlString: string): string | null {
        const path = Url.getPath(urlString);

        return path?.replace(/^\/(fr|de)\//, '/') ?? null;
    }
}

export default Url;
