import { window } from '../globals';
import ErrorLogger from '../error-logger/ErrorLogger';
import { withSiteUrl } from './url';
import { FetchResponseNotOkError } from './FetchResponseNotOkError';

const requestInfoToString = (input: Request | string | URL): string => {
    if (typeof input === 'string') {
        return input;
    }

    if (input instanceof URL) {
        return input.toString();
    }

    return input.url;
};

export function addBaseUrlToRequest(input: string): string;
export function addBaseUrlToRequest(input: Request): Request;
export function addBaseUrlToRequest(input: RequestInfo): RequestInfo;
export function addBaseUrlToRequest(input: RequestInfo|URL): RequestInfo;
export function addBaseUrlToRequest(input: URL): string;
export function addBaseUrlToRequest(input: Parameters<Window['fetch']>[0]): Parameters<Window['fetch']>[0] {
    if (typeof input === 'string') {
        return withSiteUrl(input);
    }

    if (input instanceof URL) {
        return input;
    }

    return {
        ...input,
        url: withSiteUrl(input.url),
    };
}

const assertOkResponse = (response: Response): void => {
    if (!response.ok && response.status !== 501) {
        throw new FetchResponseNotOkError(`Failed to fetch data: ${response.statusText} (${String(response.status)})`, response);
    }
};

const fetch: Window['fetch'] = async (input, init) => {
    if (!window) {
        const error = new Error('Unable to preform fetch request in node environment');

        ErrorLogger.createFromGlobals()?.log(error, {
            requestUrl: requestInfoToString(input),
        });

        throw error;
    }

    // eslint-disable-next-line no-restricted-properties
    const response = await window.fetch(addBaseUrlToRequest(input), init);
    assertOkResponse(response);

    return response;
};

/**
 * @internal Only exposed to allow resetting cache for tests etc.
 */
export class FetchCache {
    public static cache: Record<string, ReturnType<Window['fetch']>> = {};
}

export const cachedFetch: Window['fetch'] = async (input, init) => {
    const key = `${JSON.stringify(input)}${JSON.stringify(init)}`;

    const cachedResponse = FetchCache.cache[key];
    if (cachedResponse) {
        const realResponse = await cachedResponse;
        return realResponse.clone();
    }

    const realResponse = fetch(input, init);
    FetchCache.cache[key] = realResponse;

    const response = await realResponse;
    return response.clone();
};

export default fetch;
