import { useCallback, useContext } from 'react';
import { TopLayerContext } from '../../../general/TopLayer/TopLayerContext';
import MobileMenu from '../Components/AccountButton/Menu/Mobile/MobileMenu';
import SideBar from '../../../general/SideBar/SideBar';

const useShowMobileMenu = () => {
    const { closeTopLayerElement, createTopLayerElement } = useContext(TopLayerContext);

    return useCallback(() => createTopLayerElement(<MobileMenu key="mysurprise-mobile-menu" onClose={closeTopLayerElement}/>, SideBar.ANIMATION_TIME), [closeTopLayerElement, createTopLayerElement]);
};

export default useShowMobileMenu;
