import type React from 'react';
import { createContext } from 'react';

export type WebshopContextData = {
    assetsUrl: string;
    contextKey: string;
    experimentFlag: string;
    experimentVariation: string;
    hasActiveCoolerNotification: boolean;
    hasTemperatureNotification: boolean;
    isMobile: boolean;
    language: string;
    siteUrl: string;
    staticUrl: string;
};

/* istanbul ignore next */
const error = (property: string) => new Error(`Attempted to use WebshopContext ${property} outside of the context`);

/* istanbul ignore next */
const defaultData: WebshopContextData = {
    get assetsUrl(): never {
        throw error('assetsUrl');
    },
    get contextKey(): never {
        throw error('contextKey');
    },
    get experimentFlag(): never {
        throw error('experimentFlag');
    },
    get experimentVariation(): never {
        throw error('experimentVariation');
    },
    get hasActiveCoolerNotification(): never {
        throw error('hasActiveCoolerNotification');
    },
    get hasTemperatureNotification(): never {
        throw error('hasTemperatureNotification');
    },
    get isMobile(): never {
        throw error('isMobile');
    },
    get language(): never {
        throw error('language');
    },
    get siteUrl(): never {
        throw error('siteUrl');
    },
    get staticUrl(): never {
        throw error('staticUrl');
    },
};

export const WebshopContext = createContext<WebshopContextData>(defaultData);

interface WebshopContextProviderProps {
    data: WebshopContextData;
}

const WebshopContextProvider: React.FC<React.PropsWithChildren<WebshopContextProviderProps>> = ({ children, data }) => (
    <WebshopContext.Provider value={data}>
        {children}
    </WebshopContext.Provider>
);

export default WebshopContextProvider;
