import { document, window } from '../globals';

const BASE_ASSETS = window?.siteMetadata?.assetsUrl || '/assets/';
const BASE_SITE_URL = window?.SITE_URL || document?.getElementsByTagName('base').item(0)?.href || '/';

export const withBaseUrl = (baseUrl: string) => (url: string): string => {
    if (/http(s?):\/\//.exec(url)) {
        return url;
    }

    return baseUrl + ((url[0] === '/') ? url.substr(1) : url);
};

export const alterParamInSearchParams = (searchQuery: string, param: string, value: string): string => {
    const urlSearchParams = new URLSearchParams(searchQuery);
    urlSearchParams.set(param, value);
    return `?${urlSearchParams.toString()}`;
};

export const withImageUrl = withBaseUrl(`${BASE_ASSETS}images/`);
export const withSiteUrl = withBaseUrl(BASE_SITE_URL);
