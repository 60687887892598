import type { FC } from 'react';
import classNames from 'classnames';
import Button, { Size } from '../../../../../../general/Button/Button';
import styles from './Header.module.scss';

interface Props {
    onClose?: () => void;
    onPrevious?: () => void;
    title: string;
}

const Header: FC<Props> = ({ onClose, onPrevious, title }) => {
    const shouldRenderPreviousButton = typeof onPrevious === 'function';

    return (
        <header className={styles.header}>
            <Button
                className={classNames({ [styles.hidden as string]: !shouldRenderPreviousButton })}
                onClick={onPrevious}
                size={Size.sm}
                isSquare
                isGhost
                iconOnly
                icon="fa-arrow-left-long"
                aria-label="back"
            />

            <span className={styles.title}>{title}</span>

            <Button
                onClick={onClose}
                size={Size.sm}
                isSquare
                isGhost
                iconOnly
                icon="fa-xmark"
                aria-label="close"
            />
        </header>
    );
};

export default Header;
