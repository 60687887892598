import type { QueryClientConfig } from '@tanstack/react-query';
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { QueryClient } from '@tanstack/react-query';
import type { FetchResponseNotOkError } from '../../../js/api/FetchResponseNotOkError';

const createQueryClient = (defaults?: QueryClientConfig, retries = 1): QueryClient => new QueryClient({
    ...defaults,
    defaultOptions: {
        queries: {
            refetchIntervalInBackground: false,
            refetchOnMount: false,
            refetchOnReconnect: false,
            refetchOnWindowFocus: false,
            ...defaults?.defaultOptions?.queries,
            retry: (failureCount, error) => {
                if (failureCount >= retries) {
                    return false;
                }

                const statusCode = (error as FetchResponseNotOkError)?.response?.status ?? 0;

                // Bail out of retries immediately if the status code is not in the specified 500 range,
                // because we know successive retries would yield the same result.
                return [502, 503, 504].includes(Number(statusCode));
            },
        },
    },
});

export default createQueryClient;
