import { useState, useEffect } from 'react';
import { window } from '../../js/globals';

export interface WindowSize {
    height: number;
    width: number;
}

const useWindowSize = (): WindowSize => {
    const [windowSize, setWindowSize] = useState<WindowSize>({
        height: window?.innerHeight ?? 0,
        width: window?.innerWidth ?? 0,
    });

    useEffect(() => {
        const handleResize = () => {
            setWindowSize({
                height: window?.innerHeight ?? 0,
                width: window?.innerWidth ?? 0,
            });
        };

        window?.addEventListener('resize', handleResize);

        return () => {
            window?.removeEventListener('resize', handleResize);
        };
    }, []);

    return windowSize;
};

export default useWindowSize;
