import { lazy, Suspense, useCallback, useContext } from 'react';
import { TopLayerContext } from '../../../general/TopLayer/TopLayerContext';
import type { PublicRoute } from '../../../mysurprise/Shared/Navigation/AuthenticationRoutes';
import SideBar from '../../../general/SideBar/SideBar';

const useMySurpriseDrawerControls = () => {
    const { createTopLayerElement } = useContext(TopLayerContext);

    const openMySurpriseDrawer = useCallback((route?: PublicRoute, loginRedirectPath?: string | null) => {
        const LazyMySurpriseDrawer = lazy(() => import('../Components/MySurpriseDrawer/MySurpriseDrawer'));

        createTopLayerElement(
            <Suspense key="mysurprise-drawer">
                <LazyMySurpriseDrawer initialRoute={route} loginRedirectPath={loginRedirectPath}/>
            </Suspense>,
            SideBar.ANIMATION_TIME,
        );
    }, [createTopLayerElement]);

    return {
        openMySurpriseDrawer,
    };
};

export default useMySurpriseDrawerControls;
