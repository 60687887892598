import type { FC } from 'react';
import { useContext } from 'react';
import classNames from 'classnames';
import AuthorizedMenuContent from '../Content/AuthorizedMenuContent';
import UnauthorizedMenuContent from '../Content/UnauthorizedMenuContent';
import SideBar from '../../../../../../general/SideBar/SideBar';
import { AuthContext } from '../../../../../../mysurprise/Shared/Provider/AuthenticationProvider/AuthenticationProvider';
import styles from '../../AccountButton.module.scss';
import Body from '../../../../../../general/SideBar/SideBarContent/SideBarBody';
import Header from '../../../MySurpriseDrawer/Layout/Header/Header';
import useTranslate from '../../../../../../general/Translation/hooks/UseTranslate';

interface Props {
    onClose: () => void;
}

const MobileMenu: FC<Props> = ({ onClose }) => {
    const { loggedIn } = useContext(AuthContext);
    const translate = useTranslate();

    const title = translate('mysurprise_header_widget', loggedIn ? 'welcome_title' : 'login_title');

    return (
        <SideBar noTextAlign isMobileDynamicHeight>
            <Header title={title} onClose={onClose}/>

            <Body>
                <div className={classNames(styles.menu)} onClick={(e) => e.stopPropagation()}>
                    {loggedIn ? <AuthorizedMenuContent hideSubtitle/> : <UnauthorizedMenuContent hideSubtitle onClose={onClose}/>}
                </div>
            </Body>
        </SideBar>
    );
};

export default MobileMenu;
