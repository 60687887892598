import type { FC } from 'react';
import { useContext } from 'react';
import classNames from 'classnames';
import AuthorizedMenuContent from '../Content/AuthorizedMenuContent';
import UnauthorizedMenuContent from '../Content/UnauthorizedMenuContent';
import { AuthContext } from '../../../../../../mysurprise/Shared/Provider/AuthenticationProvider/AuthenticationProvider';
import styles from '../../AccountButton.module.scss';

interface Props {
    onClose: () => void;
}

const DesktopMenu: FC<Props> = ({ onClose }) => {
    const { loggedIn } = useContext(AuthContext);

    return (
        <div className={classNames('account-menu', styles.menu, styles.desktop, { [styles.authorized as string]: loggedIn })} onClick={(e) => e.stopPropagation()}>
            {loggedIn ? <AuthorizedMenuContent/> : <UnauthorizedMenuContent onClose={onClose}/>}
        </div>
    );
};

export default DesktopMenu;
