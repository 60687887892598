export enum PublicRoute {
    EMAIL_CONFIRMATION_PENDING = '/confirm-email',
    EMAIL_LOGIN = '/login',
    FORGOT_PASSWORD = '/forgot-password',
    REGISTER = '/register',
    REGISTER_STEP_2 = '/register/step-2',
    REGISTER_STEP_3 = '/register/step-3',
    RESET_PASSWORD = '/password-reset/:token',
    WELCOME = '/welcome',
}

export const previousNavigationMapping: Record<string, PublicRoute> = {
    [PublicRoute.EMAIL_LOGIN]: PublicRoute.WELCOME,
    [PublicRoute.FORGOT_PASSWORD]: PublicRoute.WELCOME,
    [PublicRoute.REGISTER]: PublicRoute.WELCOME,
    [PublicRoute.REGISTER_STEP_2]: PublicRoute.REGISTER,
    [PublicRoute.REGISTER_STEP_3]: PublicRoute.REGISTER_STEP_2,
};
