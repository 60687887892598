import type { RefObject } from 'react';
import { useEffect } from 'react';
import { document } from '../../../js/globals';

/**
 * Hook that detects clicks outside the passed ref
 */
const useClickOutside = (ref: RefObject<HTMLElement>, onClickOutside: () => void): void => {
    useEffect(() => {
        const handleClick = (event: MouseEvent) => {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClickOutside();
            }
        };

        document?.addEventListener('click', handleClick);

        return () => document?.removeEventListener('click', handleClick);
    }, [onClickOutside, ref]);
};

export default useClickOutside;
