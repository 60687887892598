import { useCallback, useEffect, useMemo, useState } from 'react';
import debounce from 'debounce';
import { isMobilePortrait as originalIsMobile } from '../../../js/util/sizes';
import { window } from '../../../js/globals';

type SetIsMobile = (input: boolean) => void;
export const useIsMobile = (isInitialRequestMobile: boolean): boolean => {
    const [isMobile, setIsMobile] = useState(isInitialRequestMobile);

    const doSetIsMobile = useCallback((setMobile: SetIsMobile) => {
        setMobile(originalIsMobile());
    }, []);

    const debouncedListener = useMemo(() => debounce(doSetIsMobile, 200), [doSetIsMobile]);

    useEffect(() => {
        setIsMobile(originalIsMobile());
        const listener = () => debouncedListener(setIsMobile);

        window?.addEventListener('resize', listener);

        return () => {
            window?.removeEventListener('resize', listener);
        };
    }, [debouncedListener]);

    return isMobile;
};
