import type React from 'react';
import type { PropsWithChildren } from 'react';
import classNames from 'classnames';

type ButtonStackProps = {
    className?: string;
    isFluid?: boolean;
    isMd?: boolean;
    isRow?: boolean;
    isSm?: boolean;
    isStacked?: boolean;
    isStackedXs?: boolean;
    isXs?: boolean;
};

const ButtonStack: React.FC<PropsWithChildren<ButtonStackProps>> = ({
    children,
    className,
    isFluid,
    isMd,
    isRow,
    isSm,
    isStacked,
    isStackedXs,
    isXs,
}) => (
    <div className={classNames('button__stack', className, {
        'is-fluid': isFluid,
        'is-row': isRow,
        'is-stacked': isStacked,
        'is-stacked--xs': isStackedXs,
        'mt-6': isXs,
        'mt-8': isSm,
        'mt-10': isMd,
    })}
    >
        {children}
    </div>
);

export default ButtonStack;
