import type React from 'react';
import { type PropsWithChildren, useContext } from 'react';
import classNames from 'classnames';
import styles from './SideBar.module.scss';
import { TopLayerContext } from '../TopLayer/TopLayerContext';
import Backdrop from '../../../stories/Atoms/Backdrop/Backdrop';

interface Props {
    hasDesktopOverlay: boolean;
    noTextAlign?: boolean;
}

const DesktopSideBar: React.FC<PropsWithChildren<Props>> = ({ children, hasDesktopOverlay, noTextAlign }) => {
    const { closeTopLayerElement, isOpen } = useContext(TopLayerContext);

    return (
        <>
            {hasDesktopOverlay && <Backdrop isOpen={isOpen} onClose={closeTopLayerElement}/>}
            {
                <aside
                    className={classNames(
                        'drawer',
                        styles.sideBar,
                        {
                            [styles['sideBar--slide-open'] as string]: isOpen,
                            [styles['sideBar--slide-close'] as string]: !isOpen,
                            [styles['no-align'] as string]: noTextAlign,
                        },
                    )}
                    role="dialog"
                >
                    {children}
                </aside>
            }
        </>
    );
};

export default DesktopSideBar;
