import type React from 'react';
import { createContext } from 'react';
import type { Translations } from './types/translations';

export const TranslationContext = createContext<Translations | undefined>(undefined);

interface TranslationProviderProps {
    dictionary: Translations;
}

const TranslationProvider: React.FC<React.PropsWithChildren<TranslationProviderProps>> = ({ children, dictionary }) => (
    <TranslationContext.Provider value={dictionary}>
        {children}
    </TranslationContext.Provider>
);

export default TranslationProvider;
