import type { FC, MouseEvent } from 'react';
import { useContext } from 'react';
import useMySurpriseDrawerControls from '../../../../Hooks/UseMySurpriseDrawerControls';
import { AuthContext } from '../../../../../../mysurprise/Shared/Provider/AuthenticationProvider/AuthenticationProvider';
import { PublicRoute } from '../../../../../../mysurprise/Shared/Navigation/AuthenticationRoutes';
import styles from '../../AccountButton.module.scss';
import Icon from '../../../../../../../stories/Atoms/Icon/Icon';
import ButtonStack from '../../../../../../general/Button/ButtonStack';
import Button from '../../../../../../general/Button/Button';
import useTranslate from '../../../../../../general/Translation/hooks/UseTranslate';
import { MySurpriseHeaderWidgetStateContext } from '../../../../Provider/StateProvider';
import Url from '../../../../../../../js/util/Url';

interface Props {
    hideSubtitle?: boolean;
    onClose: () => void;
}

const UnauthorizedMenuContent: FC<Props> = ({ hideSubtitle = false, onClose }) => {
    const { openMySurpriseDrawer } = useMySurpriseDrawerControls();
    const { hasPersistedData, isAuthenticating } = useContext(AuthContext);
    const { urls } = useContext(MySurpriseHeaderWidgetStateContext);
    const translate = useTranslate();

    const onMySurpriseOpenRequest = (route: PublicRoute, loginRedirectUrl?: string) => {
        const loginRedirectPath = loginRedirectUrl ? Url.getPathWithoutSubLanguage(loginRedirectUrl) : null;

        onClose();
        openMySurpriseDrawer(route, loginRedirectPath);
    };

    const onRegister = (e: MouseEvent) => {
        e.preventDefault();

        onMySurpriseOpenRequest(PublicRoute.REGISTER);
    };

    if (!hasPersistedData && isAuthenticating) {
        return null;
    }

    return (
        <>
            {!hideSubtitle && <span className={styles.subtitle}>{translate('mysurprise_header_widget', 'login_title')}</span>}
            <h2>{translate('mysurprise_header_widget', 'welcome_title')}</h2>

            <div className={styles.actions}>
                <div className={styles.action} onClick={() => onMySurpriseOpenRequest(PublicRoute.WELCOME, urls.myAccount)}>
                    <div className={styles.icon}><Icon name='fa-user' /></div>
                    {translate('mysurprise_header_widget', 'my_account_link')}
                </div>

                <div className={styles.action} onClick={() => onMySurpriseOpenRequest(PublicRoute.WELCOME, urls.myOrders)}>
                    <div className={styles.icon}><Icon name='fa-box' /></div>
                    {translate('mysurprise_header_widget', 'my_orders_link')}
                </div>

                <div className={styles.action} onClick={() => onMySurpriseOpenRequest(PublicRoute.WELCOME, urls.personalInfo)}>
                    <div className={styles.icon}><Icon name='fa-address-card' /></div>
                    {translate('mysurprise', 'personal_information')}
                </div>
            </div>

            <ButtonStack isFluid className="mt-4">
                <Button isPrimary onClick={() => onMySurpriseOpenRequest(PublicRoute.WELCOME)}>
                    {translate('mysurprise_header_widget', 'log_in_button')}
                </Button>
            </ButtonStack>

            <div className={styles.register}>
                <p className="p">{translate('mysurprise_header_widget', 'create_account_explanation')}</p>
                <a className={styles['register-link']} onClick={onRegister} href="">
                    {translate('mysurprise_header_widget', 'create_account_button')}
                </a>
            </div>
        </>
    );
};

export default UnauthorizedMenuContent;
