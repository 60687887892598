import type { FC } from 'react';
import { useContext, useEffect } from 'react';
import { useIsMobile } from '../../../../../general/hooks/useSize';
import DesktopMenu from './Desktop/DesktopMenu';
import useShowMobileMenu from '../../../Hooks/UseShowMobileMenu';
import { TopLayerContext } from '../../../../../general/TopLayer/TopLayerContext';
import { useIsInitialRequestMobile } from '../../../../../general/WebshopContext/WebshopContext';

interface Props {
    onClose: () => void;
    show: boolean;
}

const Menu: FC<Props> = ({ onClose, show }) => {
    const { isOpen } = useContext(TopLayerContext);
    const openMobileMenu = useShowMobileMenu();
    const isMobile = useIsMobile(useIsInitialRequestMobile());

    /**
     * The mobile menu is rendered in a sidebar/drawer, so we'll have call the createSideBar method, rather than just returning a component.
     */
    useEffect(() => {
        if (isMobile && show) {
            openMobileMenu();
        }
    }, [isMobile, openMobileMenu, show]);

    /**
     * Since the mobile sidebar can be closed through different methods apart from clicking a close button (like swiping),
     * we might not always detect this in order to trigger an onClose callback.
     * As a result, we keep an eye on the isOpen prop from the SideBarProvider and invoke onClose when necessary.
     */
    useEffect(() => {
        if (isMobile && show && !isOpen) {
            onClose();
        }
    }, [onClose, isMobile, isOpen, show]);

    if (isMobile || !show) {
        return null;
    }

    return <DesktopMenu onClose={onClose}/>;
};

export default Menu;
