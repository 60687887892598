import { createContext, useEffect, useState } from 'react';
import type { FC, PropsWithChildren } from 'react';
import { useQuery } from '@tanstack/react-query';
import type { UnauthorizedUserData, UserData } from '../../Query/AuthorizedUserQuery';
import authorizedUserQuery from '../../Query/AuthorizedUserQuery';
import { getPersistedAuthorizedUserData, persistAuthorizedUserData } from './PersistedAuthorizedUserData';

type ContextData = UserData & { hasPersistedData: boolean; isAuthenticating: boolean };

export const AuthContext = createContext<ContextData>(null as unknown as ContextData);

const defaultUserData: UnauthorizedUserData = { loggedIn: false, user: null };
const persistedUserData: UserData = getPersistedAuthorizedUserData() ?? defaultUserData;

const AuthenticationProvider: FC<PropsWithChildren> = ({ children }) => {
    const [placeholderData, setPlaceholderData] = useState<UserData>(defaultUserData);

    const { data, isFetching } = useQuery({
        ...authorizedUserQuery(),
        placeholderData,
    });

    /**
     * Set the persisted data after the initial render.
     * This way we don't get a mismatch with the SSR html (which doesn't have any persisted data).
     */
    useEffect(() => {
        if (persistedUserData !== defaultUserData) {
            setPlaceholderData(persistedUserData);
        }
    }, []);

    /**
     * Persist the data in session storage whenever it is retrieved
     */
    useEffect(() => {
        if (data && data !== defaultUserData) {
            persistAuthorizedUserData(data);
        }
    }, [data]);

    return (
        <AuthContext.Provider value={{
            hasPersistedData: placeholderData !== defaultUserData,
            isAuthenticating: isFetching,
            ...(data ?? placeholderData),
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthenticationProvider;
