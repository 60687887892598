import type { FC, MouseEvent } from 'react';
import { useCallback, useRef, useState, useContext } from 'react';
import { AuthContext } from '../../../../mysurprise/Shared/Provider/AuthenticationProvider/AuthenticationProvider';
import styles from './AccountButton.module.scss';
import Icon from '../../../../../stories/Atoms/Icon/Icon';
import useClickOutside from '../../../../general/hooks/UseClickOutside';
import useTranslate from '../../../../general/Translation/hooks/UseTranslate';
import { MySurpriseHeaderWidgetStateContext } from '../../Provider/StateProvider';
import Menu from './Menu/Menu';

const AccountButton: FC = () => {
    const { hasPersistedData, isAuthenticating, loggedIn, user } = useContext(AuthContext);
    const [showMenu, setShowMenu] = useState(false);
    const { urls } = useContext(MySurpriseHeaderWidgetStateContext);
    const ref = useRef(null);
    const translate = useTranslate();
    const onClose = useCallback(() => setShowMenu(false), []);

    const isLoadingAuthenticationData = !hasPersistedData && isAuthenticating;

    const onAccountButtonClicked = (event: MouseEvent<HTMLAnchorElement>) => {
        event.preventDefault();

        /**
         * Do not open anything unless we have some (persisted) auth data.
         * Otherwise, the user might experience a big content change when the fresh data is fetched from the server.
         */
        if (!hasPersistedData && isAuthenticating) {
            return;
        }

        setShowMenu((show) => !show);
    };

    useClickOutside(ref, () => setShowMenu(false));

    return (
        <div className={styles.wrapper} ref={ref}>
            <a
                href={urls.mysurpriseLogin}
                aria-label="MySurprise Login"
                onClick={onAccountButtonClicked}
                className="cartBox"
            >
                {!isLoadingAuthenticationData && (
                    <>
                        {loggedIn && user?.name && <div className={styles.name} data-clarity-mask="True">{user.name}</div>}
                        {!loggedIn && <div
                            className={styles.name}>{translate('mysurprise_header_widget', 'log_in_button')}</div>}
                    </>
                )}

                <div className="cartBox__icon">
                    <Icon name='fa-user' />
                    {loggedIn && (
                        <div className="cartBox__circle">
                            <span className="cartBox__circle__numbers">
                                <Icon style='fa-solid' name='fa-check' size='fa-sm' />
                            </span>
                        </div>
                    )}
                </div>

                <Icon style='fa-regular' name='fa-chevron-down' size='fa-xs' animation={showMenu ? 'fa-flip-vertical' : undefined} className="hide-xs" />
            </a>

            <Menu onClose={onClose} show={showMenu}/>
        </div>
    );
};

export default AccountButton;
