import type { QueryFunction, QueryKey } from '@tanstack/react-query';
import { z } from 'zod';
import type { Potential } from '../../../../js/types';
import fetch from '../../../../js/api/fetch';

const AuthorizedUserResponseScheme = z.object({
    loggedIn: z.literal(true),
    user: z.object({
        email: z.string(),
        name: z.string(),
    }),
});

const UnauthorizedUserResponseScheme = z.object({
    loggedIn: z.literal(false),
    user: z.literal(null),
});

const UserResponseScheme = z.discriminatedUnion('loggedIn', [AuthorizedUserResponseScheme, UnauthorizedUserResponseScheme]);

type AuthorizedUserData = z.infer<typeof AuthorizedUserResponseScheme>;
type UnauthorizedUserData = z.infer<typeof UnauthorizedUserResponseScheme>;
type UserData = z.infer<typeof UserResponseScheme>;

const isUserResponse = (data: unknown): data is UserData => UserResponseScheme.safeParse(data).success;

const fetchUser = async (signal?: AbortSignal): Promise<UserData> => {
    const response = await fetch('/auth/user', { signal });

    const responseData = await response.json() as Potential<UserData>;

    if (isUserResponse(responseData)) {
        return responseData;
    }

    throw new Error('Unexpected data returned');
};

type AuthorizedUserQueryOptions = {
    gcTime: number;
    queryFn: QueryFunction<UserData>;
    queryKey: QueryKey;
};

const authorizedUserQuery = (): AuthorizedUserQueryOptions => ({
    gcTime: Infinity,
    queryFn: ({ signal }) => fetchUser(signal),
    queryKey: ['authorized-user-data'],
});

export default authorizedUserQuery;

export type {
    UnauthorizedUserData,
    AuthorizedUserData,
    UserData,
};
