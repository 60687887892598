import type { LogsInitConfiguration } from '@datadog/browser-logs';
import type { Context } from '@datadog/browser-core';
import { datadogLogs } from '@datadog/browser-logs';
import { window } from '../globals';

export default class ErrorLogger {
    private static instance?: ErrorLogger;

    private constructor(config: LogsInitConfiguration) {
        datadogLogs.init(config);
    }

    public static create(config: LogsInitConfiguration): ErrorLogger {
        if (ErrorLogger.instance) {
            return ErrorLogger.instance;
        }

        ErrorLogger.instance = new ErrorLogger(config);

        ErrorLogger.instance.processErrorQueue();

        return ErrorLogger.instance;
    }

    public static createFromGlobals(additionalConfig: Partial<LogsInitConfiguration> = {}): ErrorLogger | null {
        const config = globalThis.DATADOG_INIT_CONFIG;

        if (!config) {
            return null;
        }

        return ErrorLogger.create({ ...config, ...additionalConfig });
    }

    // eslint-disable-next-line class-methods-use-this, @typescript-eslint/no-explicit-any
    public log(error: Error | string, context?: Context): void {
        const message = error instanceof Error ? error.message : error;
        datadogLogs.logger.error(message, context, error instanceof Error ? error : undefined);

        if (process.env.NODE_ENV === 'development') {
            // eslint-disable-next-line no-console
            console.error({ error, metadata: context });
        }
    }

    public processErrorQueue(): void {
        if (!window?.errorQueue) {
            return;
        }

        // By overwriting the push method to immediately log errors
        // we don't have to check the array once it's been processed
        window.errorQueue.push = (...errors: Error[]) => {
            errors.forEach((error) => this.log(error));

            // Necessary to match the signature of Array.prototype.push()
            return 0;
        };

        // Process all current errors in the queue
        window.errorQueue.forEach((error) => this.log(error));

        // Empty the error queue without reassigning it so the altered push() remains
        // This looks strange but is valid, see: https://stackoverflow.com/a/1234337
        // It's technically not necessary but it's cleaner to not have those Errors dangling in the array
        // and it makes this method idempotent by not logging those errors again
        window.errorQueue.length = 0;
    }
}
