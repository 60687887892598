import type React from 'react';
import type { PropsWithChildren } from 'react';
import { animated, config, useSpring } from '@react-spring/web';
import { useDrag } from '@use-gesture/react';
import { useCallback, useContext, useEffect } from 'react';
import classNames from 'classnames';
import styles from './SideBar.module.scss';
import { document } from '../../../js/globals';
import useWindowSize from '../../../stories/Hooks/useWindowSize';
import { TopLayerContext } from '../TopLayer/TopLayerContext';
import Backdrop from '../../../stories/Atoms/Backdrop/Backdrop';

interface Props {
    isDynamicHeight?: boolean;
    noTextAlign?: boolean;
}

const doThrow = (e: Error) => { throw e; };

const MobileSideBar: React.FC<PropsWithChildren<Props>> = ({ children, isDynamicHeight = false, noTextAlign }) => {
    const sideBarContext = useContext(TopLayerContext);
    const { closeTopLayerElement, isOpen } = sideBarContext;
    const { height: windowHeight } = useWindowSize();
    const [{ y }, set] = useSpring(() => ({ y: windowHeight }));

    const open = useCallback(() => {
        Promise.all(set.start({ config: config.stiff, immediate: false, y: 0 })).catch(doThrow);
    }, [set]);

    const close = useCallback(() => {
        closeTopLayerElement();
        Promise.all(set.start({ config: { ...config.stiff, velocity: 0.5 }, immediate: false, y: windowHeight })).catch(doThrow);
    }, [closeTopLayerElement, set, windowHeight]);

    useEffect(() => {
        if (!document) {
            return;
        }
        if (isOpen) {
            open();
        } else {
            close();
        }
    }, [isOpen, close, open]);

    const bind = useDrag(
        ({
            cancel,
            last,
            offset: [, offsetY],
        }) => {
            if (offsetY < -70) cancel();
            if (last) {
                if (offsetY > windowHeight * 0.20) {
                    close();
                    return;
                }
                open();
                return;
            }
            Promise.all(set.start({ immediate: true, y: offsetY })).catch(doThrow);
        },
        { bounds: { top: 0 }, filterTaps: true, from: () => [0, y.get()], rubberband: true },
    );

    return (
        <TopLayerContext.Provider value={{ ...sideBarContext, closeTopLayerElement: close }}>
            <Backdrop isOpen={isOpen} onClose={close}>
                <animated.div
                    className={classNames(
                        'drawer',
                        styles.sideBar,
                        {
                            [styles['no-align'] as string]: noTextAlign,
                            'drawer-dynamic-height': isDynamicHeight,
                        },
                    )}
                    {...bind()}
                    style={{
                        bottom: isDynamicHeight ? 0 : `calc(-100vh + ${windowHeight - 100}px)`,
                        y,
                    }}
                >

                    {children}
                </animated.div>
            </Backdrop>
        </TopLayerContext.Provider>
    );
};

export default MobileSideBar;
